<template>
    <LiefengContent>
        <template v-slot:title>
            采集信息
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input v-model="searchData.title" placeholder="请输入关键字" style="width: 160px"></Input>
                </FormItem>
                <FormItem>
                    <DatePicker transfer type="daterange" @on-change="changeDate" :options="dateOption" placement="bottom-end" placeholder="请选择日期段" style="width: 200px"></DatePicker>
                </FormItem>
                <Button type="primary" icon="ios-search" @click="search" style="margin-right: 10px">查询</Button>
                <Button type="primary" icon="ios-search" @click="searchMany" style="margin-right: 10px">查询重复数据</Button>
                <!-- <Button
          type="primary"
          @click="openAddModal"
          icon="ios-add"
          style="margin-right: 10px"
          >新增</Button
        >-->
                <!-- <Button
          type="primary"
          @click="exportTableData"
          style="margin-right: 10px"
        >
          <Icon type="ios-download-outline"></Icon>导出全部信息
        </Button> -->
                <Button type="primary" style="margin-right: 10px" icon="ios-stats-outline" @click="analysisFn">采集分析</Button>
                <Button type="primary" style="margin-right: 10px" icon="ios-paper-plane" @click="openSendmsg">发社区通知</Button>

                <!-- <Button
          type="success"
          icon="ios-cloud-upload-outline"
          style="margin-right: 10px"
          >导入</Button
        >-->

                <Button type="primary" @click="exportAllMessage" style="margin-right: 10px">
                    <Icon type="ios-download-outline"></Icon>
                    导出社区统计
                </Button>
                <Button type="primary" @click="exportTemplate" style="margin-right: 10px">
                    <Icon type="ios-download-outline"></Icon>
                    导出
                </Button>

                <Button type="primary" @click="cleanFormUserJoinLimit" style="margin-right: 10px">释放名额</Button>
                <Button type="primary" style="margin-right: 10px" icon="ios-repeat" @click="onJumpFormCollectionSync">同步到居民数据库</Button>
                <Button
                    type="error"
                    icon="ios-arrow-back"
                    @click="clckBack"
                    style="margin-right: 10px"
                    :style="{
                        display: !$route.query.id && $route.query.columnCode ? 'none' : '',
                    }"
                >
                    返回
                </Button>

                <!-- 创建沟通组 -->
                <Group ref="group" :informationId="$route.query.id" :userList="userList" style="margin:0 10px 10px 0"></Group>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" :loading="loading" :fixTable="true" :curPage="page" :total="total" :pagesizeOpts="[50, 200, 500, 1000]" :page-size="pageSize" @hadlePageSize="hadlePageSize" @tableSelect="tableSelect"></LiefengTable>

            <!-- 采集分析 -->
            <LiefengModal title="信息采集数据分析" :fullscreen="true" :value="analysisStatus" @input="analysisStatusFn" class="analysis">
                <template v-slot:contentarea>
                    <Table border :span-method="handleSpan" :columns="analysisTalbeColumns" :data="analysisTableData" size="small" ref="analysisTable"></Table>
                    <LiefengModal title="人员信息" height="calc(100vh - 160px)" width="85%" :value="persionStatus" @input="persionStatusFn" class="persion">
                        <template v-slot:contentarea>
                            <Table border :columns="persionTalbeColumns" :data="persionTableData" size="small" ref="analysisTable"></Table>
                            <Page
                                style="float: right; margin: 10px"
                                @on-change="persionPageFn"
                                :current="persionCurPage"
                                @on-page-size-change="persionPageSizeChange"
                                :page-size="persionPageSize"
                                :total="persionTotal"
                                :page-size-opts="[20, 50, 100, 200]"
                                size="small"
                                show-total
                                show-elevator
                                show-sizer
                            />
                        </template>
                    </LiefengModal>
                </template>
                <template v-slot:toolsbar>
                    <Button @click="analysisStatusFn(false)" type="info" style="margin-right: 10px">关闭</Button>
                    <Button type="primary" @click="exportTable">
                        <Icon type="ios-download-outline"></Icon>
                        导出
                    </Button>
                </template>
            </LiefengModal>

            <!-- 新增 -->
            <LiefengModal :title="previewTitle" :fullscreen="true" :value="previewStatus" @input="previewStatusFn">
                <template v-slot:contentarea>
                    <PreviewForm :previewFormData="previewFormData" @saveSuccess="saveSuccess" :infoId="infoId" :informationId="informationId" ref="previewForm" v-if="previewStatus"></PreviewForm>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        @click="
                            cancelModelTip = true
                            previewStatusFn(false)
                        "
                        style="margin-right: 10px"
                    >
                        关闭
                    </Button>
                    <Button type="primary" @click="saveAddSave">保存</Button>
                </template>
            </LiefengModal>
            <LiefengModal title="社区通知" :value="sendmsgStatus" @input="sendmsgStatusFn" width="700px" height="400px">
                <template #contentarea>
                    <Form :model="sendmsgForm" :rules="sendmsgValidate" :label-width="80" ref="sendmsgForm">
                        <FormItem label="通知内容" prop="content">
                            <Input v-model.trim="sendmsgForm.content" type="textarea" :maxlength="20" show-word-limit :rows="4" placeholder="请填写通知内容，限20字" />
                        </FormItem>
                        <FormItem label="提示说明" prop="remark">
                            <Input v-model.trim="sendmsgForm.remark" :maxlength="20" show-word-limit placeholder="提示用户下一步操作，限20字" />
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button
                        type="info"
                        @click="
                            cancelModelTip = true
                            sendmsgStatusFn(false)
                        "
                        style="margin-right: 10px"
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="saveSendMsg" :loading="sendmsgLoading">
                        <span v-if="!sendmsgLoading">发送</span>
                        <span v-else>发送中...</span>
                    </Button>
                </template>
            </LiefengModal>
            <LiefengModal title="查看重复数据" :value="manyStatus" @input="manyStatusFn" :fullscreen="true">
                <template #contentarea>
                    <div class="more-table">
                        <div>
                            <Table height="700" border stripe :columns="moreColumns" :loading="moreLoading" :data="moreTableData"></Table>
                        </div>

                        <div class="page-div">
                            <Page style="float: right; margin: 10px" @on-change="morePageFn" :current="moreCurPage" @on-page-size-change="morePageSizeChange" :page-size="morePageSize" :total="moreTotal" :page-size-opts="[20, 50, 100, 200]" size="small" show-total show-elevator show-sizer />
                        </div>
                    </div>
                </template>
                <template #toolsbar>
                    <Button type="info" @click="manyStatusFn(false)" style="margin-right: 10px">确定</Button>
                </template>
            </LiefengModal>

            <!-- 导出模板 -->
            <LiefengModal :value="templateStatus" title="导出" width="920" height="300" @input="changeTemplateStatus">
                <template v-slot:contentarea>
                    <div class="template-list">
                        <div class="template-box template-add" @click="customExport">
                            <Icon type="md-add" size="60" color="#2D8CF0" />
                            <p>自定义导出内容</p>
                        </div>
                        <div class="template-box">
                            <p>仅表单内容</p>
                            <p>导出的表头只有表单信息</p>
                            <Button style="position: absolute; top: 67%; left: 50%; transform: translate(-50%, 0)" type="primary" @click="customHeader">按照此模板导出</Button>
                        </div>
                        <div class="template-box">
                            <p>全部表单内容</p>
                            <p>导出的表头按照提交信息、表单信息、基本信息排序</p>
                            <Button style="position: absolute; top: 67%; left: 50%; transform: translate(-50%, 0)" type="primary" @click="exportTableData">按照此模板导出</Button>
                        </div>
                    </div>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="changeTemplateStatus(false)" style="margin-right: 10px">取消</Button>
                </template>
            </LiefengModal>

            <!-- 自定义导出 -->
            <LiefengModal :value="custom" :title="procedure == 1 ? '请选择要导出的字段' : '对已选字段进行排序（可上下拖动进行排序），导出的表格顺序按照此顺序显示。'" width="680px" height="650px" @input="changeCustom">
                <template v-slot:contentarea>
                    <Editform ref="editForm" @getImageList="getImageList" @getval="getval" :templateField="templateField" :procedure="procedure"></Editform>
                </template>
                <template v-slot:toolsbar>
                    <Button type="primary" v-if="procedure == 2" @click="enterExport" style="margin-right: 10px" :loading="enterExportLoading">确认并导出</Button>
                    <Button type="primary" @click="enterExportImage" style="margin-right: 10px" :loading="imageLoading">导出图片</Button>
                    <Button
                        type="info"
                        v-if="procedure == 1"
                        @click="
                            procedure = 2
                            $refs.editForm.getDiyList()
                        "
                        style="margin-right: 10px"
                    >
                        下一步
                    </Button>
                    <Button type="info" v-if="procedure == 2" @click="procedure = 1" style="margin-right: 10px">上一步</Button>
                    <Button type="info" @click="cancelEdit" style="margin-right: 10px">取消</Button>
                </template>
            </LiefengModal>

            <!-- 建群表单 -->
            <LiefengModal title="建群" width="400px" :value="createGroupStatus" @input="createGroupStatus = $event">
                <template v-slot:contentarea>
                    <Form style="margin: 0 10px">
                        <FormItem label="群名称" style="margin-bottom:10px">
                            <Input v-model.trim="createGroupForm.groupName" placeholder="请输入群名称" />
                        </FormItem>
                        <FormItem label="群类型" style="margin-bottom:10px">
                            <Select v-model="createGroupForm.groupType" placeholder="请选择群类型" transfer>
                                <Option v-for="item in groupTypes" :key="item.dictKey" :value="item.dictKey">{{ item.dictValue }}</Option>
                            </Select>
                        </FormItem>
                    </Form>
                    <Button type="info" style="width: calc(100% - 20px);margin: 10px" @click="onSubmitCreateGroup">提交</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/activitycollect');
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengContent from "@/components/LiefengContent3"
import PreviewForm from "@/views/form/childrens/previewForm"
import Editform from "./childrens/editForm"

import Group from "./childrens/Group.vue"

export default {
    data() {
        return {
            hasSensitive: true,
            // 删除权限
            isShowDel: "",
            endDate: "",
            startDate: "",
            dateOption: {
                shortcuts: [
                    //公布时间配置项
                    {
                        text: "1天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
                            return [end, start]
                        },
                    },
                    {
                        text: "7天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                            return [end, start]
                        },
                    },
                    {
                        text: "15天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
                            return [end, start]
                        },
                    },
                    {
                        text: "30天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            return [end, start]
                        },
                    },
                ],
            },
            searchData: {},
            talbeColumns: [],
            tableData: [],
            loading: false,
            page: 1,
            total: 0,
            pageSize: 50,
            menuCodeObj: {},
            formId: "",

            //   新增/修改
            previewStatus: false,
            previewTitle: "",
            cancelModelTip: true, //true代表关闭弹窗提示信息
            infoId: "",
            previewFormData: {},

            // 采集分析
            analysisStatus: false,
            searchList: [], //人员信息获取查询列表
            analysisTalbeColumns: [
                {
                    type: "index",
                    width: 60,
                    align: "center",
                },
                {
                    title: "题目",
                    key: "filedName",
                    minWidth: 300,
                },
                {
                    title: "答案",
                    key: "optionName",
                    minWidth: 300,
                },
                {
                    title: "所占比例(%)",
                    key: "percentage",
                    width: 200,
                    align: "center",
                },
                {
                    title: "参与人数(点击查看详情)",
                    key: "personNum",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Button",
                            {
                                props: {
                                    type: "error",
                                    ghost: true,
                                },
                                on: {
                                    click: () => {
                                        this.$Message.loading({
                                            content: "正在加载数据，请稍等...",
                                            duration: 0,
                                        })
                                        // 获取人员信息表头数据
                                        this.$get("/datamsg/api/pc/information/v2/selectDynamicFormHeader", {
                                            businessType: this.menuCodeObj.businessType,
                                            functionType: this.menuCodeObj.functionType,
                                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                                            oemCode: parent.vue.oemInfo.oemCode,
                                            terminal: "2",
                                            informationId: this.$route.query.id || this.informationId,
                                        }).then(res => {
                                            if (res.code == 200 && res.dataList) {
                                                this.persionTalbeColumns = res.dataList.map(item => {
                                                    let paramsObj = {}
                                                    if (item.fieldType == "image") {
                                                        paramsObj = {
                                                            render: (h, params) => {
                                                                if (!params.row[item.classField]) params.row[item.classField] = []
                                                                let answer = params.row[item.classField].split(",")
                                                                return answer.map(subItem => {
                                                                    return h(
                                                                        "div",
                                                                        {
                                                                            style: {
                                                                                textAlign: "center",
                                                                                padding: "10px 0",
                                                                                display: "inline-block",
                                                                                marginRight: "10px",
                                                                                cursor: "pointer",
                                                                            },
                                                                        },
                                                                        [
                                                                            h(
                                                                                "a",
                                                                                {
                                                                                    attrs: {
                                                                                        target: "view_window",
                                                                                        href: subItem,
                                                                                    },
                                                                                },
                                                                                [
                                                                                    h("img", {
                                                                                        attrs: {
                                                                                            src: subItem,
                                                                                        },
                                                                                        style: {
                                                                                            width: "40px",
                                                                                            height: "40px",
                                                                                        },
                                                                                    }),
                                                                                ]
                                                                            ),
                                                                        ]
                                                                    )
                                                                })
                                                            },
                                                        }
                                                    } else if (item.fieldType == "video") {
                                                        paramsObj = {
                                                            render: (h, params) => {
                                                                if (!params.row[item.classField]) params.row[item.classField] = []
                                                                let answer = params.row[item.classField].split(",")
                                                                return answer.map(subItem => {
                                                                    return h(
                                                                        "div",
                                                                        {
                                                                            style: {
                                                                                textAlign: "center",
                                                                                padding: "10px 0",
                                                                                display: "inline-block",
                                                                                marginRight: "10px",
                                                                                cursor: "pointer",
                                                                            },
                                                                            on: {
                                                                                click: () => {
                                                                                    let page = window.open()
                                                                                    var html = "<body style='background:black'> <div style='width:80%;margin:auto;'> <video controls width='100%' height='90%' autoplay src=" + subItem + "></video></div></body>"
                                                                                    page.document.write(html)
                                                                                },
                                                                            },
                                                                        },
                                                                        [
                                                                            h("video", {
                                                                                attrs: {
                                                                                    width: "100%",
                                                                                    height: "100px",
                                                                                    src: subItem,
                                                                                },
                                                                            }),
                                                                        ]
                                                                    )
                                                                })
                                                            },
                                                        }
                                                    } else if (item.fieldType == "file") {
                                                        paramsObj = {
                                                            render: (h, params) => {
                                                                if (!params.row[item.classField]) params.row[item.classField] = []
                                                                let answer = params.row[item.classField].split(",")
                                                                return answer.map(subItem => {
                                                                    return h(
                                                                        "a",
                                                                        {
                                                                            attrs: {
                                                                                href: subItem,
                                                                                download: subItem.substring(subItem.lastIndexOf("/") + 9),
                                                                                target: "view_window",
                                                                            },
                                                                            style: {
                                                                                display: "block",
                                                                            },
                                                                        },
                                                                        subItem.substring(subItem.lastIndexOf("/") + 9)
                                                                    )
                                                                })
                                                            },
                                                        }
                                                    } else if (item.fieldType == "date") {
                                                        paramsObj = {
                                                            render: (h, params) => {
                                                                return h("div", params.row[item.classField] ? this.$core.formatDate(new Date(params.row[item.classField]), "yyyy-MM-dd") : "")
                                                            },
                                                        }
                                                    } else if (item.fieldType == "cascader") {
                                                        paramsObj = {
                                                            render: (h, params) => {
                                                                return h("div", params.row[item.classField] ? params.row[item.classField].replace(/\&[\0-\9]*/g, "") : "")
                                                            },
                                                        }
                                                    } else {
                                                        paramsObj = {
                                                            key: item.classField,
                                                        }
                                                    }
                                                    return {
                                                        minWidth: 200,
                                                        ...paramsObj,
                                                        renderHeader: (h, params) => {
                                                            return h("div", {
                                                                domProps: {
                                                                    innerHTML: item.pageFieldName,
                                                                },
                                                                style: {
                                                                    width: "100%",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                },
                                                            })
                                                        },
                                                    }
                                                })
                                                res.dataList.map(item => {
                                                    if (item.fieldId == params.row.fieldId) {
                                                        this.searchList = []
                                                        this.searchList.push({
                                                            classField: item.classField,
                                                            fieldType: item.fieldType,
                                                            tbField: item.tbField,
                                                            tbValue: params.row.optionName,
                                                        })
                                                    }
                                                })
                                                // 为表头添加填写人姓名，手机号，填写时间
                                                let arr = [
                                                    {
                                                        title: "填写人姓名",
                                                        key: "creator",
                                                    },
                                                    {
                                                        title: "填写人手机号",
                                                        key: "phone",
                                                    },
                                                    {
                                                        title: "填写时间",
                                                        key: "gmtCreate",
                                                    },
                                                ]
                                                arr.map(item => {
                                                    this.persionTalbeColumns.push({
                                                        title: item.title,
                                                        minWidth: 200,
                                                        key: item.key,
                                                    })
                                                })
                                                // 获取人员信息
                                                this.persionHadlePageSize()
                                            } else {
                                                this.$Message.error({
                                                    background: true,
                                                    content: res.desc,
                                                })
                                            }
                                        })
                                    },
                                },
                            },
                            params.row.personNum
                        )
                    },
                },
            ],
            analysisTableData: [],
            filterTable: [],
            //人员信息
            persionStatus: false,
            persionTalbeColumns: [],
            persionTableData: [],
            persionCurPage: 1,
            persionPageSize: 20,
            persionTotal: 0,

            // 越权进入信息采集
            informationId: "",

            //导出
            exportFormTitleData: [],

            // 社区通知
            sendmsgStatus: false,
            sendmsgForm: {
                content: "",
                remark: "",
            },
            sendmsgLoading: false,
            sendmsgValidate: {
                content: [{ required: true, message: "请填写通知内容", trigger: "blur" }],
                remark: [{ required: true, message: "请填写提示说明", trigger: "blur" }],
            },
            userList: [],
            openSendmsgIndex: 1, //辨别是否第一次点击发社区通知
            manyStatus: false,
            morePage: 1,
            moreCurPage: 1,
            morePageSize: 20,
            moreTotal: 0,
            moreColumns: [],
            moreLoading: false,
            moreTableData: [],
            chuanColor: "red",
            otherColumns: [],
            lastCusId: "",

            // 导出模板
            templateStatus: false,
            templateList: [],

            custom: false,
            // 编辑步骤
            procedure: 1,
            // 表头字段
            templateField: [
                {
                    formName: "基本资料",
                    authFieldList: [],
                },
                {
                    formName: "表单信息",
                    authFieldList: [],
                },
                {
                    formName: "提交人信息",
                    authFieldList: [],
                },
            ],
            diyList: [],
            enterExportLoading: false,
            iamgeList: [],
            imageLoading: false,

            // 建群
            createGroupStatus: false,
            createGroupForm: {
                groupName: "",
                groupType: "",
            },
            // 沟通组类型
            groupTypes: null,
            // 沟通组信息
            groupInfo: null,
        }
    },
    methods: {
        cleanFormUserJoinLimit() {
            this.$Message.loading({
                content: "正在释放",
            })
            this.$post("/gateway/syinfopublish/api/pc/dynamicform/cleanFormUserJoinLimit", {
                informationId: this.informationId || this.$route.query.id,
            }).then(res => {
                this.$Message.destroy()
                if (res && res.code == 200) {
                    this.hadlePageSize({
                        page: this.page,
                        pageSize: this.pageSize,
                    })
                }
            })
        },
        clckBack() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push({
                    path: "/activityindex",
                    query: { menuId: this.$core.getUrlParam("menuId") },
                })
            }
        },
        // 获取表格表头信息
        getMoreTableTitle() {
            this.$get("/datamsg/api/pc/information/v2/selectDynamicFormHeader", {
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                terminal: "2",
                informationId: this.informationId || this.$route.query.id, //跨权限操作，url传informationId，直接从菜单进入采集信息
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        if (res.dataList[0]) {
                            this.formId = res.dataList[0].formId
                        }
                        this.exportFormTitleData = JSON.parse(JSON.stringify(res.dataList)) //保存表头信息，用于导出
                        this.otherColumns = []
                        this.otherColumns = res.dataList.map(item => {
                            let paramsObj = {}
                            if (item.fieldType == "image" || item.fieldType == "signature") {
                                paramsObj = {
                                    render: (h, params) => {
                                        if (!params.row[item.classField]) {
                                            params.row[item.classField] = ""
                                        } else {
                                            let answer = params.row[item.classField].split(",")
                                            return answer.map(subItem => {
                                                return h(
                                                    "div",
                                                    {
                                                        style: {
                                                            textAlign: "center",
                                                            padding: "10px 0",
                                                            display: "inline-block",
                                                            marginRight: "10px",
                                                            cursor: "pointer",
                                                        },
                                                    },
                                                    [
                                                        h(
                                                            "a",
                                                            {
                                                                attrs: {
                                                                    target: "view_window",
                                                                    href: subItem,
                                                                },
                                                            },
                                                            [
                                                                h("img", {
                                                                    attrs: {
                                                                        src: subItem,
                                                                    },
                                                                    style: {
                                                                        width: "40px",
                                                                        height: "40px",
                                                                    },
                                                                }),
                                                            ]
                                                        ),
                                                    ]
                                                )
                                            })
                                        }
                                    },
                                }
                            } else if (item.fieldType == "video") {
                                paramsObj = {
                                    render: (h, params) => {
                                        if (!params.row[item.classField]) params.row[item.classField] = []
                                        let answer = params.row[item.classField].split(",")
                                        return answer.map(subItem => {
                                            return h(
                                                "div",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                        padding: "10px 0",
                                                        display: "inline-block",
                                                        marginRight: "10px",
                                                        cursor: "pointer",
                                                    },
                                                    on: {
                                                        click: () => {
                                                            let page = window.open()
                                                            var html = "<body style='background:black'> <div style='width:80%;margin:auto;'> <video controls width='100%' height='90%' autoplay src=" + subItem + "></video></div></body>"
                                                            page.document.write(html)
                                                        },
                                                    },
                                                },
                                                [
                                                    h("video", {
                                                        attrs: {
                                                            width: "100%",
                                                            height: "100px",
                                                            src: subItem,
                                                        },
                                                    }),
                                                ]
                                            )
                                        })
                                    },
                                }
                            } else if (item.fieldType == "file") {
                                paramsObj = {
                                    render: (h, params) => {
                                        if (!params.row[item.classField]) {
                                            params.row[item.classField] = ""
                                        } else {
                                            let answer = params.row[item.classField].split(",")
                                            return answer.map(subItem => {
                                                return h(
                                                    "a",
                                                    {
                                                        attrs: {
                                                            href: subItem,
                                                            download: subItem.substring(subItem.lastIndexOf("/") + 9),
                                                            target: "view_window",
                                                        },
                                                        style: {
                                                            display: "block",
                                                        },
                                                    },
                                                    subItem.substring(subItem.lastIndexOf("/") + 9)
                                                )
                                            })
                                        }
                                    },
                                }
                            } else if (item.fieldType == "date") {
                                paramsObj = {
                                    render: (h, params) => {
                                        return h("div", params.row[item.classField] ? this.$core.formatDate(new Date(params.row[item.classField]), "yyyy-MM-dd") : "")
                                    },
                                }
                            } else if (item.fieldType == "cascader") {
                                paramsObj = {
                                    render: (h, params) => {
                                        return h(
                                            "div",
                                            params.row[item.classField]
                                                ? params.row[item.classField]
                                                      .replace(/\#/g, "")
                                                      .replace(/\@/g, "")
                                                      .replace(/\&[\0-\9]*/g, "")
                                                : ""
                                        )
                                    },
                                }
                            } else {
                                paramsObj = {
                                    key: item.classField,
                                }
                            }

                            return {
                                minWidth: 200,
                                ...paramsObj,
                                renderHeader: (h, params) => {
                                    return h("div", {
                                        domProps: {
                                            innerHTML: item.pageFieldName,
                                        },
                                        style: {
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        },
                                    })
                                },
                            }
                        })
                        this.moreColumns = [
                            {
                                title: "填写人",
                                key: "creator",
                                width: 150,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.creator
                                    )
                                },
                            },
                            {
                                title: "提交手机号",
                                key: "phone",
                                width: 150,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.phone
                                    )
                                },
                            },
                            {
                                title: "年龄",
                                key: "age",
                                width: 150,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.age
                                    )
                                },
                            },
                            {
                                title: "性别",
                                key: "sex",
                                width: 150,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.sex
                                    )
                                },
                            },
                            {
                                title: "身份证号码",
                                key: "idNum",
                                width: 180,
                            },
                            {
                                title: "所属区县",
                                key: "zone",
                                width: 150,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.zone
                                    )
                                },
                            },
                            {
                                title: "所属街道",
                                key: "street",
                                width: 150,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.street
                                    )
                                },
                            },
                            {
                                title: "所属社区",
                                key: "orgName",
                                width: 150,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.orgName
                                    )
                                },
                            },
                            {
                                title: "提交时间",
                                key: "gmtCreate",
                                width: 170,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.gmtCreate
                                    )
                                },
                            },

                            {
                                title: "被填人姓名",
                                key: "agentName",
                                width: 170,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.agentName
                                    )
                                },
                            },
                            {
                                title: "被填人手机号",
                                key: "agentMobile",
                                width: 170,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.agentMobile
                                    )
                                },
                            },
                            {
                                title: "被填人年龄",
                                key: "agentAge",
                                width: 170,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.agentAge
                                    )
                                },
                            },
                            {
                                title: "被填人性别",
                                key: "agentSex",
                                width: 170,
                                render: (h, params) => {
                                    return h(
                                        "span",
                                        {
                                            style: {
                                                color: params.row.color ? params.row.color : "",
                                            },
                                        },
                                        params.row.agentSex
                                    )
                                },
                            },
                        ]
                        this.otherColumns.map(item => {
                            this.moreColumns.unshift(item)
                        })
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        morePageFn(val) {
            this.moreCurPage = val
            let data = {
                page: this.moreCurPage,
                pageSize: this.morePageSize,
            }
            this.getManyList(data)
        },
        morePageSizeChange(val) {
            this.morePageSize = val
            this.moreCurPage = 1
            let data = {
                pageSize: this.morePageSize,
                page: this.morePage,
            }
            this.getManyList(data)
        },
        manyStatusFn(status) {
            this.manyStatus = status
        },
        searchMany() {
            this.manyStatus = true
            this.morePage = 1
            this.morePageSize = 20
            this.moreCurPage = 1
            this.getManyList({
                page: this.morePage,
                pageSize: this.morePageSize,
            })
            this.getMoreTableTitle()
        },

        pushColor(allArr, colorArr, index) {
            if (index == 0) {
                colorArr.push(
                    "#" +
                        Math.random()
                            .toString(16)
                            .slice(2, 8)
                )
                index++
                this.pushColor(allArr, colorArr, index)
            } else if (index <= allArr.length - 1) {
                if (allArr[index].custGlobalId == allArr[index - 1].custGlobalId) {
                    colorArr.push(colorArr[index - 1])
                    index++
                    this.pushColor(allArr, colorArr, index)
                } else {
                    index++
                    colorArr.push(
                        "#" +
                            Math.random()
                                .toString(16)
                                .slice(2, 8)
                    )
                    this.pushColor(allArr, colorArr, index)
                }
            } else {
                return colorArr
            }
        },

        getManyList(data) {
            this.moreLoading = true
            this.$post(
                "/datamsg/api/pc/information/question/selectDistinctDynamicFormDataPage",
                {
                    businessType: this.menuCodeObj.businessType,
                    functionType: this.menuCodeObj.functionType,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    informationId: this.$route.query.id || this.informationId,
                    oemCode: parent.vue.oemInfo.oemCode,
                    terminal: "2",
                    globalFile: this.searchData.title,
                    formId: this.formId,
                    page: data.page,
                    pageSize: data.pageSize,
                    startDate: this.startDate,
                    endDate: this.endDate,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                this.moreLoading = false
                if (res.code == 200 && res.dataList) {
                    res.dataList.map(item => {
                        item.gmtCreate = item.gmtCreate ? this.$core.formatDate(new Date(item.gmtCreate), "yyyy-MM-dd hh:mm:ss") : ""
                    })
                    this.moreTableData = res.dataList.map(item => {
                        item.sex = item.sex == "1" ? "男" : item.sex == "2" ? "女" : ""

                        item.agentSex = item.agentSex == "1" ? "男" : item.agentSex == "2" ? "女" : ""
                        if (item.custGlobalId == null) {
                            item.custGlobalId = item.agentCustGlobalId
                        }

                        return item
                    })

                    console.log("改变后的table", this.moreTableData)
                    // let List = this.pushColor(this.moreTableData);
                    let List = []
                    this.pushColor(this.moreTableData, List, 0)
                    console.log("获取到的", List)
                    for (var i = 0; i < List.length; i++) {
                        this.moreTableData[i].color = List[i]
                    }
                    this.morePage = res.currentPage
                    this.morePageSize = res.pageSize
                    this.moreTotal = res.maxCount
                    if (this.moreTableData.length == 20) {
                        this.lastCusId = this.moreTableData.pop().custGlobalId
                    }
                } else {
                    this.moreLoading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        // 点击导出全部信息按钮
        exportAllMessage() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "确认导出全部信息",
                onOk: () => {
                    this.$get("/datamsg/api/pc/information/v2/downloadAcquisitionAnalysis", {
                        businessType: this.menuCodeObj.businessType,
                        functionType: this.menuCodeObj.functionType,
                        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        oemCode: parent.vue.oemInfo.oemCode,
                        terminal: "2",
                        informationId: this.$route.query.id || this.informationId,
                        orgLevel: 5,
                    }).then(res => {
                        if (res.code == 200) {
                            if (res.data) {
                                if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }
                            console.log(res.data)
                            let link = document.createElement("a")
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            link.download = "导出全部信息"
                            document.body.removeChild(link)
                            this.$Message.destroy()
                        } else {
                            this.$Message.warning({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        // 发社区通知
        openSendmsg() {
            if (this.tableData.length == 0) {
                this.$Modal.error({
                    title: "温馨提示",
                    content: "暂无可接收通知的人员",
                })
            } else if (this.openSendmsgIndex == 1) {
                this.$Modal.info({
                    title: "温馨提示",
                    onOk: () => {
                        this.openSendmsgIndex = 2
                        this.sendmsgStatus = true
                    },
                    content: "默认给所有填写人发送社区通知，若只需给指定填写人发送，请从表格中勾选数据或输入查询条件后再发！",
                })
            } else {
                this.sendmsgStatus = true
            }
        },
        saveSendMsg() {
            this.$refs.sendmsgForm.validate(status => {
                if (status) {
                    this.sendmsgLoading = true
                    this.$post(
                        "/gateway/syinfopublish/api/pc/information/question/pushMessageCustomize",
                        {
                            functionType: this.menuCodeObj.functionType,
                            content: this.sendmsgForm.content,
                            remark: this.sendmsgForm.remark,
                            userList: (() => {
                                let arr = this.userList.map(item => {
                                    return {
                                        custGlobalId: item.custGlobalId,
                                        orgCode: item.orgCode,
                                    }
                                })
                                return arr.length > 0 ? arr : null
                            })(),
                            informationId: this.$route.query.id,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            globalFile: this.searchData.title,
                            startDate: this.startDate ? this.startDate : null,
                            endDate: this.endDate ? this.endDate : null,
                        },
                        { "Content-Type": "application/json" }
                    )
                        .then(res => {
                            if (res.code == 200) {
                                this.sendmsgLoading = false
                                this.$Message.success({
                                    background: true,
                                    content: "发送成功",
                                })
                                this.cancelModelTip = false
                                this.sendmsgStatus = false
                                this.userList = []
                                this.hadlePageSize({
                                    page: this.page,
                                    pageSize: this.pageSize,
                                })
                            } else {
                                this.sendmsgLoading = false
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            this.sendmsgLoading = false
                            this.$Message.error({
                                background: true,
                                content: "发送失败",
                            })
                        })
                }
            })
        },
        tableSelect(arr) {
            let userList = []

            this.userList.map(item => {
                if (item.page != this.page) {
                    userList.push(item)
                }
            })
            this.userList = userList
            arr.map(item => {
                let custGlobalId = ""
                if (item.custGlobalId) {
                    this.userList.push({
                        custGlobalId: item.custGlobalId,
                        orgCode: item.orgCode,
                        id: item.id,
                        page: this.page,
                    })
                } else {
                    this.userList.push({
                        custGlobalId: item.agentCustGlobalId,
                        orgCode: item.orgCode,
                        id: item.id,
                        page: this.page,
                    })
                }
            })
            this.userList = this.$core.onlyList(this.userList, "custGlobalId", "id")
            console.log(this.userList)
        },
        sendmsgStatusFn(status) {
            if (!status && this.cancelModelTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                    onOk: () => {
                        this.sendmsgStatus = status
                    },
                })
            } else {
                this.sendmsgStatus = status
                this.$refs.sendmsgForm.resetFields()
            }
        },
        // 日期改变
        changeDate(val) {
            this.startDate = val[0]
            this.endDate = val[1]
        },
        // 导出采集信息表格
        exportTableData() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "确认导出全部信息",
                onOk: () => {
                    this.$post(
                        "/info/api/pc/information/question/downloadDynamicForm",
                        {
                            businessType: this.menuCodeObj.businessType,
                            functionType: this.menuCodeObj.functionType,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            informationId: this.$route.query.id || this.informationId,
                            oemCode: parent.vue.oemInfo.oemCode,
                            terminal: "2",
                            globalFile: this.searchData.title,
                            formId: this.formId,
                            startDate: this.startDate,
                            endDate: this.endDate,
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            if (res.data) {
                                if (res.data.slice(0, 5) == "http") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }
                            console.log(res.data)

                            let link = document.createElement("a")
                            link.download = "采集信息数据导出.xlsx"
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            this.$Message.destroy()
                            this.templateStatus = false
                        } else {
                            this.$Message.warning({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
            // this.$Modal.confirm({
            //   title: "温馨提示",
            //   content:
            //     "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
            //   onOk: () => {
            //     let tHeader = [];
            //     let filterVal = [];
            //     this.exportFormTitleData.map((item) => {
            //       if (item.fieldType == "cascader") {
            //         tHeader.push(item.pageFieldName);
            //         filterVal.push(
            //           item.classField
            //             ? item.classField.replace(/\&[\0-\9]*/g, "")
            //             : ""
            //         );
            //       } else if (item.fieldType != "image" && item.fieldType != "file") {
            //         tHeader.push(item.pageFieldName);
            //         filterVal.push(item.classField);
            //       }
            //     });
            //     let talbeColumnsArr = [
            //       {
            //         title: "填写人",
            //         key: "creator",
            //       },
            //       {
            //         title: "提交手机号",
            //         key: "phone",
            //       },
            //       {
            //         title: "年龄",
            //         key: "age",
            //         width: 150,
            //       },
            //       {
            //         title: "性别",
            //         key: "sex",
            //         width: 150,
            //       },
            //       {
            //         title: "所属社区",
            //         key: "orgName",
            //       },
            //       {
            //         title: "提交时间",
            //         key: "gmtCreate",
            //       },
            //       {
            //         title: "是否代填",
            //         key: "agentCustGlobalId",
            //       },
            //       {
            //         title: "被填人姓名",
            //         key: "agentName",
            //       },
            //       {
            //         title: "被填人手机号",
            //         key: "agentMobile",
            //       },
            //       {
            //         title: "被填人年龄",
            //         key: "agentAge",
            //       },
            //       {
            //         title: "被填人性别",
            //         key: "agentSex",
            //       },
            //     ];
            //     talbeColumnsArr.map((item) => {
            //       tHeader.push(item.title);
            //       filterVal.push(item.key);
            //     });
            //     this.$core.exportExcel(
            //       tHeader,
            //       filterVal,
            //       this.tableData,
            //       "采集信息列表"
            //     );
            //   },
            // });
        },
        // 新增/修改
        previewStatusFn(status) {
            if (!status && this.cancelModelTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                    onOk: () => {
                        this.previewStatus = status
                    },
                })
            } else {
                this.previewStatus = status
            }
        },
        //新增获取表单信息
        openAddModal() {
            this.$Message.loading({
                content: "正在获取数据，请稍等...",
                duration: 0,
            })
            this.$get("/datamsg/api/pc/dynamicform/findFormByType", {
                formType: this.$route.query.id || this.informationId,
            })
                .then(res => {
                    this.$Message.destroy()
                    if (res.code == 200 && res.data) {
                        this.infoId = ""
                        this.previewFormData = res.data
                        this.previewTitle = "新增采集信息"
                        this.previewStatus = true
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 保存新增修改
        saveAddSave() {
            this.$refs.previewForm.save()
        },
        //人员信息
        persionStatusFn(status) {
            this.persionStatus = status
        },

        persionHadlePageSize() {
            this.$post(
                "/gateway/syinfopublish/api/pc/information/appointment/queryDynamicFormDataPage",
                {
                    businessType: this.menuCodeObj.businessType,
                    functionType: this.menuCodeObj.functionType,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    informationId: this.$route.query.id || this.informationId,
                    oemCode: parent.vue.oemInfo.oemCode,
                    terminal: "2",
                    globalFile: this.searchData.title,
                    formId: this.formId,
                    startDate: "",
                    page: this.persionCurPage,
                    pageSize: this.persionPageSize,
                    endDate: "",
                    searchList: this.searchList,
                    hasSensitive: false,
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        res.dataList.map(item => {
                            item.gmtCreate = this.$core.formatDate(new Date(item.gmtCreate), "yyyy-MM-dd hh:mm:ss")
                        })
                        this.persionTableData = res.dataList
                        this.persionTotal = res.maxCount
                        this.persionCurPage = res.currentPage
                        this.persionPageSize = res.pageSize
                        this.$Message.destroy()
                        this.persionStatus = true
                    } else {
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        persionPageSizeChange(val) {
            this.persionPageSize = val
            this.persionCurPage = 1
            this.persionHadlePageSize()
        },
        persionPageFn(val) {
            this.persionCurPage = val
            this.persionHadlePageSize()
        },
        // 采集分析
        exportTable() {
            this.$refs.analysisTable.exportCsv({
                filename: "信息采集数据分析",
                columns: JSON.parse(JSON.stringify(this.analysisTalbeColumns)).slice(1),
                data: this.analysisTableData,
            })
        },
        handleSpan({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 1 || columnIndex === 0) {
                let arr = this.analysisTableData.slice(0, rowIndex)
                if (JSON.stringify(arr).indexOf(row.fieldId) != -1) {
                    return [0, 0]
                } else {
                    return [row.analyzeFieldVos.length, 1]
                }
            }
        },
        analysisFn() {
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            this.$get("/datamsg/api/pc/information/v2/selectAcquisitionAnalysis", {
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                terminal: "2",
                informationId: this.$route.query.id || this.informationId,
            })
                .then(res => {
                    this.$Message.destroy()
                    if (res.code == 200 && res.dataList) {
                        this.analysisTableData = []
                        res.dataList.map(item => {
                            if (item.analyzeFieldVos) {
                                item.analyzeFieldVos.map(subItem => {
                                    this.analysisTableData.push({
                                        percentage: subItem.percentage,
                                        optionName: subItem.optionName,
                                        personNum: subItem.personNum,
                                        ...item,
                                        dynamicBusiVos: subItem.dynamicBusiVos,
                                    })
                                })
                            }
                        })
                        this.analysisStatus = true
                    } else if (res.code == 200 && !res.dataList) {
                        this.$Message.error({
                            background: true,
                            content: "未查询到采集信息",
                        })
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.destroy()
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        analysisStatusFn(status) {
            this.analysisStatus = status
        },

        // 保存成功后执行
        saveSuccess() {
            this.cancelModelTip = false
            this.previewStatus = false
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        // 获取表格表头信息
        getTableTitle() {
            this.$get("/datamsg/api/pc/information/v2/selectDynamicFormHeader", {
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                terminal: "2",
                informationId: this.informationId || this.$route.query.id, //跨权限操作，url传informationId，直接从菜单进入采集信息
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        if (res.dataList[0]) {
                            this.formId = res.dataList[0].formId
                        }
                        this.exportFormTitleData = JSON.parse(JSON.stringify(res.dataList)) //保存表头信息，用于导出
                        this.talbeColumns = res.dataList.map(item => {
                            let paramsObj = {}
                            if (item.fieldType == "image") {
                                paramsObj = {
                                    render: (h, params) => {
                                        if (!params.row[item.classField]) {
                                            params.row[item.classField] = ""
                                        } else {
                                            let answer = params.row[item.classField].split(",")
                                            return answer.map(subItem => {
                                                return h(
                                                    "div",
                                                    {
                                                        style: {
                                                            textAlign: "center",
                                                            padding: "10px 0",
                                                            display: "inline-block",
                                                            marginRight: "10px",
                                                            cursor: "pointer",
                                                        },
                                                    },
                                                    [
                                                        h(
                                                            "a",
                                                            {
                                                                attrs: {
                                                                    target: "view_window",
                                                                    href: subItem,
                                                                },
                                                            },
                                                            [
                                                                h("img", {
                                                                    attrs: {
                                                                        src: subItem,
                                                                    },
                                                                    style: {
                                                                        width: "40px",
                                                                        height: "40px",
                                                                    },
                                                                }),
                                                            ]
                                                        ),
                                                    ]
                                                )
                                            })
                                        }
                                    },
                                }
                            } else if (item.fieldType == "video") {
                                paramsObj = {
                                    render: (h, params) => {
                                        if (!params.row[item.classField]) params.row[item.classField] = []
                                        let answer = params.row[item.classField].split(",")
                                        return answer.map(subItem => {
                                            return h(
                                                "div",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                        padding: "10px 0",
                                                        display: "inline-block",
                                                        marginRight: "10px",
                                                        cursor: "pointer",
                                                    },
                                                    on: {
                                                        click: () => {
                                                            let page = window.open()
                                                            var html = "<body style='background:black'> <div style='width:80%;margin:auto;'> <video controls width='100%' height='90%' autoplay src=" + subItem + "></video></div></body>"
                                                            page.document.write(html)
                                                        },
                                                    },
                                                },
                                                [
                                                    h("video", {
                                                        attrs: {
                                                            width: "100%",
                                                            height: "100px",
                                                            src: subItem,
                                                        },
                                                    }),
                                                ]
                                            )
                                        })
                                    },
                                }
                            } else if (item.fieldType == "file") {
                                paramsObj = {
                                    render: (h, params) => {
                                        if (!params.row[item.classField]) {
                                            params.row[item.classField] = ""
                                        } else {
                                            let answer = params.row[item.classField].split(",")
                                            return answer.map(subItem => {
                                                return h(
                                                    "a",
                                                    {
                                                        attrs: {
                                                            href: subItem,
                                                            download: subItem.substring(subItem.lastIndexOf("/") + 9),
                                                            target: "view_window",
                                                        },
                                                        style: {
                                                            display: "block",
                                                        },
                                                    },
                                                    subItem.substring(subItem.lastIndexOf("/") + 9)
                                                )
                                            })
                                        }
                                    },
                                }
                            } else if (item.fieldType == "date") {
                                paramsObj = {
                                    render: (h, params) => {
                                        return h("div", params.row[item.classField] ? this.$core.formatDate(new Date(params.row[item.classField]), "yyyy-MM-dd") : "")
                                    },
                                }
                            } else if (item.fieldType == "cascader") {
                                paramsObj = {
                                    render: (h, params) => {
                                        return h("div", params.row[item.classField] ? params.row[item.classField].replace(/\&[\0-\9]*/g, "") : "")
                                    },
                                }
                            } else {
                                paramsObj = {
                                    key: item.classField,
                                }
                            }

                            return {
                                minWidth: 200,
                                ...paramsObj,
                                renderHeader: (h, params) => {
                                    return h("div", {
                                        domProps: {
                                            innerHTML: item.pageFieldName,
                                        },
                                        style: {
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        },
                                    })
                                },
                            }
                        })
                        let talbeColumnsArr = [
                            {
                                title: "填写人",
                                key: "creator",
                                width: 150,
                            },
                            {
                                title: "提交手机号",
                                key: "phone",
                                width: 150,
                            },
                            {
                                title: "年龄",
                                key: "age",
                                width: 150,
                            },
                            {
                                title: "性别",
                                key: "sex",
                                width: 150,
                            },
                            {
                                title: "所属社区",
                                key: "orgName",
                                width: 150,
                            },
                            {
                                title: "提交时间",
                                key: "gmtCreate",
                                width: 170,
                            },
                            {
                                title: "是否代填",
                                key: "agentCustGlobalId",
                                width: 170,
                            },
                            {
                                title: "被填人姓名",
                                key: "agentName",
                                width: 170,
                            },
                            {
                                title: "被填人手机号",
                                key: "agentMobile",
                                width: 170,
                            },
                            {
                                title: "被填人年龄",
                                key: "agentAge",
                                width: 170,
                            },
                            {
                                title: "被填人性别",
                                key: "agentSex",
                                width: 170,
                            },
                        ]
                        talbeColumnsArr.map(item => {
                            this.talbeColumns.push({
                                width: item.width,
                                title: item.title,
                                key: item.key,
                                align: "center",
                            })
                        })
                        // 添加select
                        this.talbeColumns.unshift({
                            type: "selection",
                            width: 60,
                            align: "center",
                        })
                        this.talbeColumns.push({
                            title: "操作",
                            align: "center",
                            width: 120,
                            fixed: "right",
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            textAlign: "center",
                                        },
                                    },
                                    [
                                        h(
                                            "Button",
                                            {
                                                attrs: {
                                                    type: "error",
                                                    size: "small",
                                                },
                                                on: {
                                                    click: () => {
                                                        this.$post("/verifyapi/syinfopublish/api/pc/dynamicform/deleteDynamicById", {
                                                            dynamicId: params.row.id,
                                                        }).then(res => {
                                                            if (res.code == 200) {
                                                                this.$Message.success({
                                                                    background: true,
                                                                    content: "删除成功",
                                                                })
                                                                this.hadlePageSize({
                                                                    page: this.page,
                                                                    pageSize: this.pageSize,
                                                                })
                                                            } else {
                                                                this.$Message.error({
                                                                    background: true,
                                                                    content: res.desc,
                                                                })
                                                            }
                                                        })
                                                    },
                                                },
                                                style: {
                                                    width: "65px",
                                                    textAlign: "center",
                                                    display: this.isShowDel ? "block" : "none",
                                                },
                                            },
                                            "删除" //1001
                                        ),
                                    ]
                                )
                            },
                        })
                        // this.talbeColumns.push({
                        //   fixed: "right",
                        //   width: 100,
                        //   title: "操作",
                        //   align: "center",
                        //   render: (h, params) => {
                        //     return h(
                        //       "Button",
                        //       {
                        //         props: {
                        //           type: "primary",
                        //           size: "small",
                        //         },
                        //         on: {
                        //           click: () => {
                        //             this.infoId = params.row.id;
                        //             this.$Message.loading({
                        //               content: "正在加载数据，请稍等...",
                        //               duration: 0,
                        //             });
                        //             this.$get(
                        //               "/datamsg/api/pc/dynamicform/findDynamicDataById",
                        //               {
                        //                 dynamicId: params.row.id,
                        //               }
                        //             )
                        //               .then((res) => {
                        //                 this.$Message.destroy();
                        //                 if (res.code == 200 && res.data) {
                        //                   this.previewFormData = res.data;
                        //                   this.previewTitle = "修改采集信息";
                        //                   this.previewStatus = true;
                        //                 } else {
                        //                   this.$Message.error({
                        //                     background: true,
                        //                     content: res.desc,
                        //                   });
                        //                 }
                        //               })
                        //               .catch((err) => {
                        //                 console.log(err);
                        //                 this.$Message.destroy();
                        //                 this.$Message.error({
                        //                   background: true,
                        //                   content: "获取数据失败，请联系管理员处理",
                        //                 });
                        //               });
                        //           },
                        //         },
                        //       },
                        //       "修改"
                        //     );
                        //   },
                        // });
                        this.hadlePageSize({
                            page: this.page,
                            pageSize: this.pageSize,
                        })
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        // 获取表格内容
        hadlePageSize(obj) {
            this.loading = true
            this.$post(
                "/gateway/syinfopublish/api/pc/information/appointment/queryDynamicFormDataPage",
                {
                    businessType: this.menuCodeObj.businessType,
                    functionType: this.menuCodeObj.functionType,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    informationId: this.$route.query.id || this.informationId,
                    oemCode: parent.vue.oemInfo.oemCode,
                    terminal: "2",
                    globalFile: this.searchData.title,
                    formId: this.formId,
                    page: obj.page,
                    pageSize: obj.pageSize,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    hasSensitive: this.hasSensitive,
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList.map(item => {
                            this.userList.map(subItem => {
                                if (subItem.id == item.id) {
                                    item._checked = true
                                }
                            })
                            item.gmtCreate = item.gmtCreate ? this.$core.formatDate(new Date(item.gmtCreate), "yyyy-MM-dd hh:mm:ss") : ""
                            item.sex = item.sex == "1" ? "男" : item.sex == "2" ? "女" : ""
                            if (item.agentCustGlobalId) {
                                item.agentCustGlobalId = "是"
                                item.agentSex = item.agentSex == "1" ? "男" : item.agentSex == "2" ? "女" : ""
                            } else {
                                item.agentCustGlobalId = "否"
                            }

                            return item
                        })
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.loading = false
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        //获取表头 表单信息字段
        async getHeaderList(status) {
            await this.$post(
                "/info/api/pc/information/question/getAllHeads",
                {
                    businessType: this.menuCodeObj.businessType,
                    functionType: this.menuCodeObj.functionType,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    informationId: this.$route.query.id || this.informationId,
                    oemCode: parent.vue.oemInfo.oemCode,
                    terminal: "2",
                    globalFile: this.searchData.title,
                    formId: this.formId,
                    // page: obj.page,
                    // pageSize: obj.pageSize,
                    startDate: this.startDate,
                    endDate: this.endDate,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    res.dataList.map(e => {
                        this.templateField[1].authFieldList.push({
                            field: e.field,
                            fieldName: e.fieldName,
                            fieldValue: e.fieldValue,
                            status: false,
                        })
                    })
                    if (status) {
                        this.diyList = []
                        this.diyList = res.dataList.map(e => {
                            return {
                                field: e.field,
                                fieldName: e.fieldName,
                                fieldValue: e.fieldValue,
                                status: true,
                            }
                        })
                    }
                }
            })
        },
        // 获取基本信息 填报信息字段
        getOption() {
            this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                appCode: "SUIYUE",
                dictType: "COLLECTION_AND_ANALYSIS",
            }).then(res => {
                if (res.code == 200) {
                    res.dataList.map(e => {
                        if (e.extendValue === this.templateField[0].formName) {
                            this.templateField[0].authFieldList.push({
                                field: e.dictKey,
                                fieldName: e.dictValue,
                                fieldValue: "",
                                status: false,
                            })
                        } else if (e.extendValue === this.templateField[2].formName) {
                            this.templateField[2].authFieldList.push({
                                field: e.dictKey,
                                fieldName: e.dictValue,
                                fieldValue: "",
                                status: false,
                            })
                        }
                    })
                }
            })
        },
        exportTemplate() {
            this.templateStatus = true
        },
        // 导出模板框状态
        changeTemplateStatus(val) {
            this.templateStatus = val
        },
        // 按模板导出
        saveOperation() {},
        // 导出表单信息
        async customHeader() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "确认导出全部信息",
                onOk: async () => {
                    await this.getHeaderList(true)
                    if (!this.diyList.length) {
                        this.$Message.warning({
                            content: "暂无可导出的表单数据",
                            background: true,
                        })
                        return
                    }
                    this.$Message.loading({
                        background: true,
                        content: "正在导出表单数据，请稍等",
                    })
                    await this.$post(
                        "/info/api/pc/information/question/downloadDynamicFormCustom",
                        {
                            businessType: this.menuCodeObj.businessType,
                            functionType: this.menuCodeObj.functionType,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            informationId: this.$route.query.id || this.informationId,
                            oemCode: parent.vue.oemInfo.oemCode,
                            terminal: "2",
                            header: this.diyList,
                            globalFile: this.searchData.title,
                            formId: this.formId,
                            startDate: this.startDate,
                            endDate: this.endDate,
                            riskField: this.searchData.riskField ? this.searchData.riskField : "",
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        this.$Message.destroy()
                        if (res.code == 200) {
                            if (res.data) {
                                if (res.data.slice(0, 5) == "http") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }
                            this.excelStatus = false
                            let link = document.createElement("a")
                            link.download = "采集信息数据导出.xlsx"
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            this.$Message.destroy()
                        } else {
                            this.$Message.warning({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        // 自定义导出
        customExport() {
            this.templateField = [
                {
                    formName: "基本资料",
                    authFieldList: [],
                },
                {
                    formName: "表单信息",
                    authFieldList: [],
                },
                {
                    formName: "提交人信息",
                    authFieldList: [],
                },
            ]
            this.getHeaderList()
            this.getOption()
            this.procedure = 1
            this.custom = true
        },
        changeCustom(val) {
            this.custom = val
        },
        getval(val) {
            this.diyList = val
        },
        getImageList(val) {
            this.iamgeList = val
        },
        // 自定义导出
        enterExport() {
            console.log(this.diyList)
            if (this.diyList.length == 0) {
                this.$Message.warning({
                    content: "请选择导出字段",
                    background: true,
                })
            } else {
                // this.diyList.map(e => {
                //   delete e.status
                // })
                this.enterExportLoading = true
                this.$post(
                    "/info/api/pc/information/question/downloadDynamicFormCustom",
                    {
                        businessType: this.menuCodeObj.businessType,
                        functionType: this.menuCodeObj.functionType,
                        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                        informationId: this.$route.query.id || this.informationId,
                        oemCode: parent.vue.oemInfo.oemCode,
                        terminal: "2",
                        header: this.diyList,
                        globalFile: this.searchData.title,
                        formId: this.formId,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        riskField: this.searchData.riskField ? this.searchData.riskField : "",
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    this.enterExportLoading = false
                    if (res.code == 200) {
                        if (res.data) {
                            if (res.data.slice(0, 5) == "http") {
                                res.data = res.data.replace("http", "https")
                            }
                        }
                        console.log(res.data)
                        this.templateStatus = false
                        this.custom = false
                        let link = document.createElement("a")
                        link.download = "采集信息数据导出.xlsx"
                        link.href = res.data
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        this.$Message.destroy()
                    } else {
                        this.$Message.warning({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
            }
        },
        // 导出图片
        enterExportImage() {
            console.log(this.diyList)
            if (this.iamgeList.length == 0) {
                this.$Message.warning({
                    content: "请选择导出图片字段",
                    background: true,
                })
            } else {
                this.imageLoading = true
                this.$post(
                    "/info/api/pc/information/question/downloadImageZip",
                    {
                        businessType: this.menuCodeObj.businessType,
                        functionType: this.menuCodeObj.functionType,
                        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                        informationId: this.$route.query.id || this.informationId,
                        oemCode: parent.vue.oemInfo.oemCode,
                        terminal: "2",
                        header: this.iamgeList,
                        globalFile: this.searchData.title,
                        formId: this.formId,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        riskField: this.searchData.riskField ? this.searchData.riskField : "",
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    this.imageLoading = false
                    if (res.code == 200) {
                        if (res.data) {
                            if (res.data.slice(0, 5) == "http") {
                                res.data = res.data.replace("http", "https")
                            }
                        }
                        console.log(res.data)
                        // this.excelStatus = false
                        // this.templateStatus = false
                        // this.custom = false
                        let link = document.createElement("a")
                        link.download = "采集信息图片.zip"
                        link.href = res.data
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        this.$Message.destroy()
                    } else {
                        this.$Message.warning({
                            content: res.desc,
                            background: true,
                        })
                    }
                })

                // var _this = this
                // this.imageLoading = true
                // let url = vue.getProxy()["/info"].target + "/api/pc/information/question/downloadImageZip"
                // var xhr = new XMLHttpRequest();
                // xhr.open('post',url,true)
                // xhr.setRequestHeader("Content-type","application/json");
                // xhr.setRequestHeader('Authorization', window.sessionStorage.getItem("accessToken"));
                // xhr.responseType = "blob";
                // xhr.onload = function () {
                //   if (this.status == 200) {
                //     _this.imageLoading = false
                //     //接受二进制文件流
                //     let res = this.response;
                //     console.log(res)
                //     _this.downloadExportFile(res, "采集信息图片",'zip')
                //   }
                // }
                // let obj = {
                //   businessType: this.menuCodeObj.businessType,
                //   functionType: this.menuCodeObj.functionType,
                //   operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                //   orgCode: parent.vue.loginInfo.userinfo.orgCode,
                //   custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                //   informationId: this.$route.query.id || this.informationId,
                //   oemCode: parent.vue.oemInfo.oemCode,
                //   terminal: "2",
                //   header:this.iamgeList,
                //   globalFile: this.searchData.title,
                //   formId: this.formId,
                //   startDate: this.startDate,
                //   endDate: this.endDate,
                //   riskField:this.searchData.riskField ? this.searchData.riskField : ''
                // }
                // let xhrObj = JSON.stringify(obj)
                // xhr.send(xhrObj);
            }
        },

        cancelEdit() {
            this.$refs.editForm.reset()
            this.changeCustom(false)
        },
        downloadExportFile(res, fileName, type) {
            const blob = new Blob([res], { type: `application/${type}` })
            const a = document.createElement("a")
            a.href = URL.createObjectURL(blob)
            a.download = fileName + ".zip"
            a.click()
            URL.revokeObjectURL(a.href)
            a.remove()
        },

        /**
         * 跳转到同步到居民数据库
         */
        onJumpFormCollectionSync() {
            this.$router.push(`/formcollectionsynctable?formId=${this.formId}&informationId=${this.$route.query.id || this.informationId}&back=${encodeURIComponent(this.$route.fullPath)}`)
        },
    },
    async created() {
        let userButtonRoot = parent.vue.userButtonRoot
        this.isShowDel = userButtonRoot.includes("DYNAMIC:DATA:DEL") ? true : false
        if (this.$route.query.code && this.$route.query.columnCode) {
            //informationId,跨权限操作，直接从菜单进入采集信息
            await this.$get("/datamsg/api/pc/menu/selectColumnCodeInfo", {
                columnCode: this.$route.query.columnCode,
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
            // 根据url上的code，查表单id
            await this.$get("/datamsg/api/pc/information/v2/queryByCode", {
                code: this.$route.query.code,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                terminal: "2",
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.informationId = res.data.id
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        } else {
            // 获取functionType/columnCode等
            await this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
                menuCode: this.$core.getUrlParam("menuId"),
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                }
            })
        }

        this.getTableTitle()

        // 获取沟通组信息
        this.getGroupInfo()
    },
    components: {
        LiefengTable,
        LiefengModal,
        LiefengContent,
        PreviewForm,
        Editform,
        Group,
    },
}
</script>

<style scoped lang="less">
// /deep/#toolsbarRight {
//   width: 100%;
// }
.search {
    /deep/.ivu-btn {
        margin-bottom: 10px;
    }
}
/deep/.ivu-modal-close {
    display: none;
}
.analysis {
    .ivu-table-wrapper {
        height: calc(100vh - 123px);
    }
    /deep/.ivu-table-tip {
        height: calc(100vh - 160px);
        table {
            height: 100%;
        }
    }
    /deep/.ivu-table {
        overflow: auto;
    }
}
.persion {
    .ivu-table-wrapper {
        height: calc(100vh - 218px);
    }
    /deep/.ivu-table-tip {
        height: calc(100vh - 252px);
        table {
            height: 100%;
        }
    }
    /deep/.ivu-table {
        overflow: auto;
    }
    /deep/.ivu-table-body {
        height: calc(100% - 38px);
    }
    /deep/.ivu-modal-close {
        display: block;
    }
}
.template-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    position: relative;
    .template-add {
        cursor: pointer;
        /deep/.ivu-icon-md-add:before {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        p {
            position: absolute;
            left: 50%;
            top: 60%;
            line-height: 60px !important;
            transform: translate(-50%, 0);
        }
    }
    .template-box {
        text-align: center;
        margin: 20px;
        width: 260px;
        height: 220px;
        border: 1px solid #999999;
        border-radius: 5px;
        position: relative;

        p {
            &:nth-of-type(1) {
                font-size: 20px;
                line-height: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:nth-of-type(2) {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                padding: 0 10px;
                box-sizing: border-box;
            }
            &:nth-of-type(3) {
                position: absolute;
                left: 50%;
                bottom: 5%;
                transform: translate(-50%, 0);
                width: 100%;
            }
        }
    }
    .eye {
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        margin: 0 15px 0;
    }
}
</style>
